header {
    height: 165px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 72px;
}

header img {
    max-width: 132px;
    max-height: 162px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

header .right {
    transform: scale(-1, 1) 
}

header .company-and-nav-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

header .nav-panel, header .company-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

header .company-row {
    height: 112px;
}

header .company-name p {
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 16px;
}

header .number p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 2px 0;
}

header p, header a {
    color: var(--text);
    margin: 0;
}

header .nav-panel {
    height: 50px;
}

header .nav-item:active,
header .nav-item:hover, 
header .nav-item {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    text-transform: uppercase;
}

nav {
    display: block;
    align-self: auto;
    flex: 0 auto;
    transition: 2.5s ease all;
}

.navbar-toggler {
    border: none;
    border-radius: 0px;
    padding: 0px;
    cursor: pointer;
    margin-top: 41px;
    margin-bottom: 23px; 
    background-color: var(--main);
    display: none;
}

.navbar-toggler[aria-expanded="false"] span:nth-child(2) {
    opacity: 1; 
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0; 
}

.navbar-toggler[aria-expanded="true"] span:first-child {
    transform: rotate(-45deg);
    position: relative;
    top: 11.5px; 
}

.navbar-toggler[aria-expanded="true"] span:last-child {
    transform: rotate(45deg);
    bottom: 7px;
    position: relative; 
}

.navbar-toggler span {
    display: block;
    width: 30px;
    height: 5px;
    background: #fff;
    margin: auto;
    margin-bottom: 4px;
    transition: all 400ms linear;
    cursor: pointer; 
}

@media (max-width: 1080px) {
    /*.number {
        display: none;
    }*/
    header img {
        display: none;
    }
}

@media (max-width: 910px) {
    header {
        height: auto;
        margin: 0;
    }
    header .company-row {
        margin-right: 70px;
    }
    .navbar-toggler {
        display: block;
        position: absolute;
        right: 30px;
    }
    header .nav-panel {
        display: none;
    }
    nav[aria-expanded="true"] {
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;
        width: 100%;
        border-bottom: 2px solid var(--text);
        border-top: 2px solid var(--text);
    }
    nav[aria-expanded="true"] a, nav[aria-expanded="true"] a:active, nav[aria-expanded="true"] a:hover {
        margin: 15px 0;
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    header .number p {
        font-size: 14px;
    }
    header .company-name p{
        font-size: 20px;
    }
    .navbar-toggler {
        right: 20px;
    }
    header .company-row {
        margin-right: 40px;
    }
    nav[aria-expanded="true"] a, nav[aria-expanded="true"] a:active, nav[aria-expanded="true"] a:hover {
        margin: 10px 0;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .number{
        display: none;
    }
    header .company-name p {
        font-size: 18px;
    }
    .navbar-toggler {
        right: 10px;
    }
    header .company-row {
        margin-right: 20px;
    }
}