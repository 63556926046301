.about-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.image-block {
    width: 400px;
    height: 350px;
}

.image-block img {
    max-width: 400px;
    max-height: 350px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.text-block {
    max-width: 400px;
}

.about-section h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: var(--text);
    text-decoration: underline var(--text);
    margin: 0;
    margin-bottom: 20px;
}

.about-section p {
    color: var(--text);
    line-height: 20px;
    font-size: 13px;
    margin: 0;
    margin-bottom: 10px;
}

.about-section .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
}

.legal-information table{
    width: 100%;
}

.legal-information table p{
    line-height: 20px;
    font-size: 13px;
    color: var(--text);
    margin: 0;
    padding: 8px;
}

.legal-information table, .legal-information tr, .legal-information td {
    border: 1px solid black;
    border-collapse: collapse;
}

@media (max-width: 1080px) {
    .about-section, .legal-information {
        margin: 0 20px;
        margin-bottom: 40px;
    }
}

@media (max-width: 820px) {
    .image-block {
        height: auto;
        width: auto;
    }
    .about-section {
        flex-direction: column-reverse;
    }
    h2 {
        margin-bottom: 20px;
    }
    .image-block {
        margin-left: 0;
        margin-bottom: 40px;
    }
}

@media (max-width: 480px) {
    .about-section .title {
        font-size: 14px;
    }
    .about-section p {
        font-size: 12px;
    }
    .image-block {
        width: 100%;
        position: relative;
    }
    .image-block img {
        max-width: 100%;
    }
}