.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.content h2 {
    margin-bottom: 60px;
}

tbody svg {
    max-width: 54px;
    max-height: 54px;
    margin-right: 20px;
    margin: auto ;
}

tbody td {
    padding-right: 20px;
    text-align: left;
    vertical-align: top;
}

.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 6px;
}

.sub-title {
    line-height: 20px;
    font-size: 13px;
    margin: 0;
    margin-bottom: 10px;
}

iframe {
    width: 640px;
    height: 500px;
}

@media (max-width: 1400px) {
    .content {
        margin: 0 20px;
    }
}

@media (max-width: 1080px) {
    .content {
        flex-direction: column;
    }
    iframe {
        margin-top: 20px;
        width: 90vw;
        height: 50vw;
    }
}

@media (max-width: 640px) {
    .content h2 {
        margin-bottom: 20px;
        font-size: 18px;
    }
    tbody svg {
        max-width: 32px;
        max-height: 32px;
        margin-right: 10px;
    }
    tbody td {
        padding-right: 0;
    }
    .content {
        width: 90vw;
        margin: 0 auto;
    }
}