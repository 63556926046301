:root {
  --main-blue: #41B4F4;
  --sub-main-blue: #CEEBF3;
  --text: #343543;
  --white: #F0F3F5;
}

.main-pg {
  background: linear-gradient(180deg, #41B4F4 0%, #CEEBF3 20%, #CEEBF3 80%, #41B4F4 100%);
}

body {
  background-color: var(--white);
  background: linear-gradient(180deg, #41B4F4 0%, rgba(65, 180, 244, 0) 20%, rgba(255, 255, 255, 0) 80%, #41B4F4 100%), #F0F3F5;
  
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

body::-webkit-scrollbar {
  width: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  margin: 0 auto;
  width: 1008px;
}

h1 {
  margin-top: 26px;
  margin-bottom: 50px;
  font-weight: 500;
  font-size: 32px;
  line-height: 26px;
}

h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--text);
}

@media (max-width: 1080px) {
  h1, h2 {
    text-align: center;
  }
}


@media (max-width: 1008px) {
  main {
    width: 100%;
  }
}

@media (max-width: 640px) {
  h1 {
    margin-bottom: 20px;
    font-size: 26px;
  } 
  h2 {
    font-size: 18px;
  }
}