@keyframes slideDown {
    0% {
      opacity: 0;
      max-height: 0;
    }

    100% {
      opacity: 1;
      max-height: 1000px;
    }
}

@keyframes Rotate {
    0% {
      -webkit-transform: initial;
      -moz-transform: initial;
      -ms-transform: initial;
      -o-transform: initial;
      transform: initial;
    }

    100% {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
}

.items details {
  max-width: 1080px;
    border: 2px solid white;
    background: white;
    margin: 15px 0;
    border-radius: 15px;
    transition: all .2s ease-out;
    position: relative;
    width: 100%;
}

.items details summary {
    cursor: pointer;
    padding: 10px 20px;
    list-style: none;
    display: flex;
}

.items details:hover {
    border-color: var(--text);
}

.summary-title {
    font-weight: 500;
    font-size: 16px;
    color: var(--text);
    text-align: center;
    vertical-align: middle;
    line-height: 32px;
}

.summary-chevron-up {
    display: none;
    margin: auto 0 auto auto;
    position: relative;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.summary-chevron-down {
    display: block;
    margin: auto 0 auto auto;
    pointer-events: none;
    position: relative;
}

details[open] .summary-chevron-up {
    display: block;
    animation-name: Rotate;
    animation-duration: 250ms;
    animation-timing-function: ease-in;
    overflow: hidden;
  }

  details[open] .summary-chevron-down {
    display: none;
  }

  details[close] .summary-chevron-up {
    display: none;
  }

  details[close] .summary-chevron-down {
    display: block;
  }

  details[open] summary~* {
    transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    animation: slideDown .5s ease-in-out;
    overflow: hidden;
}

.summary-content {
  padding: 0 21px 21px 21px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.summary-content .servise-img {
  width: 50%;
  height: 250px;
  padding-right: 20px;
  position: relative;
  display: block;
}

.summary-content .servise-img img {
  max-width: 100%;
  max-height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.summary-content .description {
  width: 50%;
}

.summary-content .description p {
  font-size: 13px;
  line-height: 20px;
  margin: 0;
}

@media (max-width: 1080px) {
  .items details {
    margin: 15px auto;
  }
  .info-section {
    width: calc(100% - 72px);
    margin: 0 auto;
  }
}

@media (max-width: 640px) {
  .summary-content .description p, .summary-content .servise-img img {
    font-size: 14px;
  }
  .info-section p {
    font-size: 14px;
  }
  .summary-title {
    font-size: 14px;
    line-height: 28px;
  }
  .items details summary {
    padding: 5px 15px;
  }
  .items details summary svg{
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 440px) {
  .summary-title {
    font-size: 14px;
    line-height: 22px;
  }
  .summary-content .description p, .summary-content .servise-img img {
    font-size: 12px;
  }
  .items details summary {
    padding: 5px 5px;
  }
  .items details summary svg{
    width: 24px;
    height: 20px;
  }
}