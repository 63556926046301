.catalog-detail {
    padding: 0 15px;
    margin: 0 auto;
    width: 756px;
}

.catalog-detail .top-section {
    padding: 31px 28px;
    border: 1px solid #d7dee3;
    display: flex;
}

.catalog-detail .information {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    position: relative;
}

.catalog-detail h2, .catalog-detail p {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
}

.catalog-detail hr {
    height: 1px;
    margin: 0;
    border-top: 1px solid #d7dee3;
}

.catalog-detail .question-btn {
    color: var(--text);
    border: 1px solid var(--text);
    width: calc(100% - 30px);
    padding: 7px 12px 8px;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    margin: 30px 0;
}

.catalog-detail .share p {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0;
}

.catalog-detail .ya-share2 {
    padding: 10px 0;
}

.catalog-detail .botton-section {
    position: relative;
    margin-bottom: 60px;
}

.catalog-detail .botton-section h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--text);
    display: inline-block;
    margin-bottom: 30px;
}

.catalog-detail table {
    width: 100%;
}

.catalog-detail .char td {
    padding: 0;
    font-style: italic;
}

.catalog-detail .char {
    position: relative;
    display: flex;
}

.catalog-detail .char .dotted {
    border-bottom: 2px dotted #aaa;
    flex: 1;
}

.catalog-detail .char_name, .catalog-detail .char_value {
    flex: 0 0 auto;
    display: inline-block;
}

.catalog-detail .char_name span, .catalog-detail .char_value span {
    font-size: 14px;
}

.catalog-detail .char_name span{
    font-weight: 500;
    padding-right: 5px;
}

.catalog-detail .char_value span {
    padding-left: 5px;
    font-weight: 400;
}

.catalog-detail .carousel-slider {
    width: 314px;
    height: 300px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.catalog-detail .carousel-slider img {
    max-width: 314px;
    max-height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

@media (max-width: 1080px) {
    .information h2 {
        text-align: left;
    }
}

@media (max-width: 910px) {
    .catalog-detail {
        padding: 0;
        margin: 0 10px;
    }
    .catalog-detail .top-section {
        flex-direction: column;
    }
    .catalog-detail .information {
        padding-left: 0;
    }
    .catalog-detail .carousel-slider {
        max-width: 314px;
        width: auto;
    }
}

@media (max-width: 400px) {
    .catalog-detail .botton-section h3 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        padding-bottom: 5px;
    }
    .catalog-detail .char_name span, .catalog-detail .char_value span {
        font-size: 14px;
    }
}