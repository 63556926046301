.catalog-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.catalog-content a {
    text-decoration: none;
    color: var(--text);
}

.items-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*max-width: 700px;*/
}

.sections {
    border-bottom: 1px solid var(--text);
    overflow: hidden;
    padding: 0;
    margin: 0;
    margin-right: 40px;
    min-width: 222px;
}

.sections li {
    border-top: 1px solid var(--text);
    line-height: 16px;
    display: block;
    position: relative;
    list-style: none;
    padding: 13px;
}

.sections .active {
    background-color: var(--text);
}

.sections .active>a {
    color: var(--white);

}

.sections li a {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.catalog-item {
    height: 100px;
    width: 250px;
    margin: 20px;
    background-color: var(--sub-main-blue);
    border-radius: 15px;
    padding: 10px 30px;
    display: inline-block;
}

.catalog-item .item-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    float: left;
}

.catalog-item img {
    max-width: 100px;
    max-height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


.catalog-item p:active,
.catalog-item p:hover, 
.catalog-item p {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    margin-top: 10px;
}

.item-card {
    width: 190px;
    height: 310px;
    padding: 20px;
    margin: 8px;
    transition: all ease 0.5s;
    border-radius: 15px;
    border: 1px solid var(--text);
}

.item-card:hover {
    transform: scale(1.05);
}

.item-card .item-card-image{
    width: 190px;
    height: 230px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed {
    width: 100%;
}

.item-card img {
    max-width: 190px;
    max-height: 230px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.item-card p {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
    margin-bottom: 10px;
    color: var(--text);
    max-height: 36px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;  
}

.item-card .value {
    margin: 0;
    font-weight: bold;
}

.submenu {
    margin-top: 13px;
    padding: 0;
    border-top: 1px solid var(--white);
}

.submenu li {
    border-bottom: 1px solid var(--white);
    padding: 8px 13px;
}

.submenu li>a {
    color: var(--white);
    font-size: 12px;
}

@media (max-width: 1080px) {
    .items-list {
        justify-content: center;
    }
    .item-card {
        margin: 10px auto;
    }
}

@media (max-width: 640px) {
    .sections {
        display: none;
    }
}

@media (max-width: 480px) {
    .catalog-item {
        padding: 10px 15px;
    }
    .catalog-item .item-image {
        margin-right: 10px;
    }
}