footer {
    height: 150px;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

footer p, footer a {
    color: var(--text);
    margin: 0;
}

footer .copyrite-panel p {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}

footer .link-panel{
    width: 40vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

footer .link-panel a {
    margin: 0 10px;
}

footer .number-panel p {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}

footer .nav-item:active,
footer .nav-item:hover, 
footer .nav-item {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    text-transform: uppercase;
}

@media (max-width: 550px) {
    footer {
        flex-direction: column-reverse;
        margin: 0 22px;
        margin-top: 20px;
    }
    footer div {
        margin-bottom: 10px;
    }
}